import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,{staticStyle:{"background-color":"#d4d8e8"}},[_c(VContainer,{staticStyle:{"max-width":"800px","padding-top":"50px"}},[_c(VCard,{attrs:{"loading":_vm.loading,"elevation":"2","outlined":""}},[_c('div',{staticClass:"text-center py-4"},[_c('img',{attrs:{"width":"250","src":require("../../assets/euromedi_logo.png")}})]),_c(VDivider),_c(VCardText,{staticStyle:{"padding":"50px"}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('h2',{staticClass:"mb-3"},[_vm._v("Connexion administrateurs")]),(_vm.err)?_c(VAlert,{attrs:{"border":"right","type":"error","elevation":"2","icon":"fas fa-exclamation-circle"}},[_vm._v(_vm._s(_vm.err))]):_vm._e(),_c(VTextField,{attrs:{"rules":[_vm.required],"filled":"","name":"email","type":"email","label":_vm.$t('E-mail')}}),_c(VTextField,{attrs:{"rules":[_vm.required],"filled":"","name":"password","type":"password","label":_vm.$t('Mot de passe')}}),_c(VBtn,{attrs:{"type":"submit","color":"primary","id":"login_button"}},[_vm._v(" Se connecter ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }