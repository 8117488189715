import LayoutVue from "@/components/Layout.vue";
import LayoutAdminVue from "@/components/LayoutAdmin.vue";
import { get } from "@/plugins/axios";
import ExpiredLinkVue from "@/views/ExpiredLink.vue";
import FormRegisterVue from "@/views/FormRegister.vue";
import FormCliHospVue from "@/views/FormCliHosp.vue";
import FormCliMonoVue from "@/views/FormCliMono.vue";
import FormMRSVue from "@/views/FormMRS.vue";
import Login from "@/views/admin/Login.vue";
import Institution from "@/views/admin/Institution.vue";
import InstitutionDetails from "@/views/admin/InstitutionDetails.vue";
import Inexistant from "@/views/Inexistant.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import FormValidatedVue from "@/views/FormValidated.vue";
import FormHomeCareVue from "@/views/FormHomeCare.vue";
import FormRadio from "@/views/FormRadio.vue";
import FormRevalidation from "@/views/FormRevalidation.vue";
import FormHandicapVue from "@/views/FormHandicap.vue";
import FormFormation from "@/views/FormFormation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "layout",
    component: LayoutVue,
    meta: {
      auth: false,
    },
    children: [
      {
        path: "/",
        name: "home",
        component: FormRegisterVue,
        meta: {
          auth: false,
        },
      },
      {
        path: "/expired",
        name: "expired",
        component: ExpiredLinkVue,
        meta: {
          auth: false,
        },
      },
      {
        path: "/1",
        name: "1",
        component: FormCliHospVue,
        meta: {
          auth: true,
        },
      },
      {
        path: "/2",
        name: "2",
        component: FormCliHospVue,
        meta: {
          auth: true,
        },
      },
      {
        path: "/3",
        name: "3",
        component: FormCliMonoVue,
        meta: {
          auth: true,
        },
      },
      {
        path: "/4",
        name: "4",
        component: FormMRSVue,
        meta: {
          auth: true,
        },
      },
      {
        path: "/5",
        name: "5",
        component: FormHomeCareVue,
        meta: {
          auth: true,
        },
      },
      {
        path: "/6",
        name: "6",
        component: FormHandicapVue,
        meta: {
          auth: true,
        },
      },
      {
        path: "/7",
        name: "7",
        component: FormRadio,
        meta: {
          auth: true,
        },
      },
      {
        path: "/8",
        name: "8",
        component: FormRevalidation,
        meta: {
          auth: true,
        },
      },
      {
        path: "/9",
        name: "9",
        component: FormFormation,
        meta: {
          auth: true,
        },
      },
      {
        path: "/validated",
        name: "validated",
        component: FormValidatedVue,
        meta: {
          auth: false,
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "layout-admin",
    component: LayoutAdminVue,
    meta: {
      auth: true,
      admin: true,
    },
    children: [
      {
        path: "/admin/institution",
        name: "institution",
        component: Institution,
        meta: {
          auth: true,
          admin: true,
        },
      },
      {
        path: "/admin/institution/:id",
        name: "institution-details",
        component: InstitutionDetails,
        meta: {
          auth: true,
          admin: true,
        },
      },
    ],
  },
  {
    path: "/admin/login",
    name: "login",
    component: Login,
    meta: {
      auth: false,
    },
  },
  {
    path: "*",
    name: "404",
    component: Inexistant,
    meta: {
      auth: false,
    },
  },
];

// routes.map(function (route, i) {
//   if (i) {
//     route.path = "/:lang" + route.path;
//   }
// });

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   if (to.meta.auth) {
//     if (!to.meta.admin) {
//       try {
//         const urlSearchParams = new URLSearchParams(window.location.search);
//         const { token } = Object.fromEntries(urlSearchParams.entries());
//         await get(`/api/validate/${token}`);
//         next();
//       } catch (e) {
//         next("/expired");
//       }
//     } else {
//       if (!localStorage.getItem("token")) {
//         next("/admin/login");
//       } else {
//         next();
//       }
//     }
//   } else {
//     next();
//   }
// });

export default router;
