import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"2","outlined":""}},[_c(VCardTitle,[_vm._v("Etablissements")]),_c(VDivider),_c(VDataTable,{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.list,"items-per-page":15},scopedSlots:_vm._u([{key:"item.show",fn:function({ item }){return [_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary","to":`/admin/institution/${item.id}`}},[_vm._v(" Voir ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }