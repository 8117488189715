import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"clipped-left":"","elevation":"3","app":"","extended":"","extension-height":"15"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c(VIcon,[_vm._v("fas fa-bars")])],1),_c(VToolbarTitle,[_c(VImg,{attrs:{"max-width":"200","src":require("../assets/euromedi_logo.png")}})],1),_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"red"},on:{"click":_vm.logout}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-power-off")]),_vm._v(" Déconnexion")],1)],1),_c(VNavigationDrawer,{attrs:{"clipped":"","app":"","dark":"","mini-variant":_vm.mini},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":""}},[_c(VListItem,{on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c(VListItemIcon,[(!_vm.mini)?_c(VIcon,[_vm._v("mdi-chevron-left")]):_c(VIcon,[_vm._v("mdi-chevron-right")])],1),_c(VListItemContent,[_vm._v(" Minimiser ")])],1),_c(VDivider,{staticClass:"my-5"}),_c(VListItem,{attrs:{"link":"","to":"/admin/institution"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("fas fa-list")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Etablissements")])],1)],1)],1)],1),_c(VMain,{staticStyle:{"background-color":"#d4d8e8"}},[_c(VContainer,{attrs:{"fluid":""}},[_c('Transition',{attrs:{"enter-active-class":"animate__animated animate__backInRight","leave-active-class":"animate__animated animate__backOutLeft","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }