<template>
  <v-app>
    <v-app-bar
      style="border-bottom: solid 5px #034ea2; padding-top: 10px"
      extended
      app
      absolute
      color="#ffffff"
      class="navbar"
      extension-height="30"
    >
      <v-toolbar-title
        ><router-link to="/"
          ><v-img
            max-width="250"
            src="../assets/euromedi_logo.png" /></router-link
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" outlined v-bind="attrs" v-on="on">
            <v-img
              :src="
                lang == 'FR'
                  ? 'https://www.euromediinternational.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/fr_FR.png'
                  : 'https://www.euromediinternational.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/en_GB.png'
              "
            />
            <span class="mx-1">{{ lang }}</span>
            <v-icon small right>fas fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="lang = 'FR'" link>
            <v-list-item-title
              ><img
                src="https://www.euromediinternational.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/fr_FR.png"
              />
              FR</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="lang = 'EN'" link>
            <v-list-item-title
              ><img
                src="https://www.euromediinternational.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/en_GB.png"
              />
              EN</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main style="background-color: #d4d8e8">
      <v-container style="max-width: 1200px; padding-top: 50px">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Layout",
  watch: {
    lang(v) {
      this.$i18n.locale = v.toLowerCase();
      localStorage.setItem("lang", v.toLowerCase());
    },
  },
  beforeMount() {
    this.lang = this.$i18n.locale.toLocaleUpperCase();
  },
  data: () => ({ lang: "FR" }),
};
</script>

<style></style>
