<template>
  <v-app>
    <v-main style="background-color: #d4d8e8">
      <v-container style="max-width: 800px; padding-top: 50px">
        <div class="text-center">
          <h1 style="font-size: 105px" class="fs-5 font-weight-bold">404</h1>
          <h1>Page introuvable!</h1>
          <v-btn class="mt-5" color="primary" to="/">Retour</v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style></style>
