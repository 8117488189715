import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{staticClass:"navbar",staticStyle:{"border-bottom":"solid 5px #034ea2","padding-top":"10px"},attrs:{"extended":"","app":"","absolute":"","color":"#ffffff","extension-height":"30"}},[_c(VToolbarTitle,[_c('router-link',{attrs:{"to":"/"}},[_c(VImg,{attrs:{"max-width":"250","src":require("../assets/euromedi_logo.png")}})],1)],1),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","outlined":""}},'v-btn',attrs,false),on),[_c(VImg,{attrs:{"src":_vm.lang == 'FR'
                ? 'https://www.euromediinternational.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/fr_FR.png'
                : 'https://www.euromediinternational.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/en_GB.png'}}),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.lang))]),_c(VIcon,{attrs:{"small":"","right":""}},[_vm._v("fas fa-chevron-down")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":""},on:{"click":function($event){_vm.lang = 'FR'}}},[_c(VListItemTitle,[_c('img',{attrs:{"src":"https://www.euromediinternational.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/fr_FR.png"}}),_vm._v(" FR")])],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){_vm.lang = 'EN'}}},[_c(VListItemTitle,[_c('img',{attrs:{"src":"https://www.euromediinternational.com/wp-content/plugins/translatepress-multilingual/assets/images/flags/en_GB.png"}}),_vm._v(" EN")])],1)],1)],1)],1),_c(VMain,{staticStyle:{"background-color":"#d4d8e8"}},[_c(VContainer,{staticStyle:{"max-width":"1200px","padding-top":"50px"}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }