<template>
  <v-row justify="center">
    <v-col lg="9">
      <v-card>
        <v-card-title class="text-h5 red lighten-3">
          <v-icon>fas fa-exclamation-circle</v-icon>
          <h3 class="mx-3">Ce lien est invalide ou expiré!</h3>
        </v-card-title>
        <v-card-text class="text-center py-5">
          <b
            >Veuiller cliquer sur le bouton en dessous et remplir le formulaire,
            vous aller recevoir un email contenant le nouveau lien:</b
          >
          <br />
          <v-btn class="mt-5" color="primary" to="/"
            >vers le formulaire
            <v-icon class="ml-3">fas fa-arrow-right</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
