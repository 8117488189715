import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API,
});

export function get(url, config) {
  return Axios.get(url, config);
}

export function post(url, body, config) {
  return Axios.post(url, body, config);
}

export function put(url, body, config) {
  return Axios.put(url, body, config);
}

export function del(url, config) {
  return Axios.delete(url, config);
}
