<template>
  <v-app>
    <v-app-bar clipped-left elevation="3" app extended extension-height="15">
      <v-btn @click.stop="drawer = !drawer" icon>
        <v-icon>fas fa-bars</v-icon></v-btn
      >
      <v-toolbar-title
        ><v-img max-width="200" src="../assets/euromedi_logo.png"
      /></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined color="red" @click="logout">
        <v-icon small class="mr-2">fas fa-power-off</v-icon> Déconnexion</v-btn
      >
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      dark
      :mini-variant.sync="mini"
    >
      <v-list nav>
        <v-list-item @click.stop="mini = !mini">
          <v-list-item-icon>
            <v-icon v-if="!mini">mdi-chevron-left</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>
          </v-list-item-icon>

          <v-list-item-content> Minimiser </v-list-item-content>
        </v-list-item>

        <v-divider class="my-5"></v-divider>

        <v-list-item link to="/admin/institution">
          <v-list-item-icon>
            <v-icon>fas fa-list</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Etablissements</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main style="background-color: #d4d8e8">
      <v-container fluid>
        <Transition
          enter-active-class="animate__animated animate__backInRight"
          leave-active-class="animate__animated animate__backOutLeft"
          mode="out-in"
        >
          <router-view />
        </Transition>
      </v-container>
    </v-main>

    <!-- <Transition
      enter-active-class="animate__animated animate__backInRight"
      leave-active-class="animate__animated animate__backOutRight"
      mode="out-in"
    >
      <v-alert
        icon="fas fa-exclamation-circle"
        v-if="alert.active"
        :text="alert.message"
        :type="alert.type"
        closable
        variant="outlined"
        style="position: fixed; bottom: 10px; right: 10px"
      ></v-alert>
    </Transition> -->
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    mini: false,
  }),
  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/admin/login");
    },
  },
};
</script>

<style>
.v-list-item:hover {
  background-color: rgba(110, 110, 110, 0.37) !important;
}
.v-list-item__overlay,
.v-list-item__underlay,
.v-list-item__overlay:hover,
.v-list-item__underlay:hover {
  background: transparent !important;
  background-color: transparent !important;
}
</style>
