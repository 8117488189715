<template>
  <v-form @submit.prevent="submit" ref="form">
    <v-row justify="center">
      <v-col lg="9">
        <h3 class="mb-3" v-if="$route.name == 1">
          {{ $t("formCliHosp.1") }}
        </h3>
        <h3 class="mb-3" v-else>
          {{ $t("formCliHosp.2") }}
        </h3>
        <v-card :disabled="loading" :loading="loading" elevation="2" outlined>
          <div style="padding: 20px">
            <v-row>
              <v-col cols="12" v-if="$route.name == 1">
                <b>{{ $t("formCliHosp.q1") }}:</b>
                <v-radio-group :rules="[required]">
                  <v-radio
                    name="class"
                    :label="$t('formCliHosp.q1o1')"
                    value="private"
                  ></v-radio>
                  <v-radio
                    name="class"
                    :label="$t('formCliHosp.q1o2')"
                    value="public"
                  ></v-radio>
                  <v-radio
                    name="class"
                    :label="$t('formCliHosp.q1o3')"
                    value="university"
                  ></v-radio>
                </v-radio-group>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <b>{{ $t("common.q1") }}</b>
                <v-radio-group :rules="[required]">
                  <v-radio
                    name="board_of_directors"
                    value="1"
                    :label="$t('yes')"
                  ></v-radio>
                  <v-radio
                    name="board_of_directors"
                    value="0"
                    :label="$t('no')"
                  ></v-radio>
                </v-radio-group>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <b>{{ $t("common.q2") }}</b>
                <v-radio-group :rules="[required]">
                  <v-radio
                    name="quality_department"
                    value="1"
                    :label="$t('yes')"
                  ></v-radio>
                  <v-radio
                    name="quality_department"
                    value="0"
                    :label="$t('no')"
                  ></v-radio>
                </v-radio-group>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <b>{{ $t("common.q3") }}</b>
                <v-radio-group :rules="[required]" v-model="isGroup">
                  <v-radio
                    name="member_of_network_group"
                    value="1"
                    :label="$t('yes')"
                  ></v-radio>
                  <v-radio
                    name="member_of_network_group"
                    value="0"
                    :label="$t('no')"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col lg="6" v-if="isGroup == 1">
                <b>{{ $t("common.q4") }}</b>
                <v-text-field
                  :rules="[required]"
                  filled
                  :name="`name_of_the_group`"
                  :label="$t('common.q4')"
                ></v-text-field>
              </v-col>

              <v-col lg="6" v-if="isGroup == 1">
                <b>{{ $t("common.q5") }}</b>
                <v-text-field
                  :rules="[required]"
                  filled
                  name="number_of_institutions"
                  hide-spin-buttons
                  type="number"
                  :label="$t('common.q5')"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-row justify="center">
                  <v-col :lg="Number(isMultiS) ? 6 : 12">
                    <b>{{ $t("Sites") }}:</b>
                    <v-radio-group :rules="[required]" v-model="isMultiS">
                      <v-radio value="0" :label="$t('common.q6o1')"></v-radio>
                      <v-radio value="1" :label="$t('common.q6o2')"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col lg="6" v-if="Number(isMultiS)">
                    <b>{{ $t("common.q7") }}:</b>
                    <v-select
                      :items="[2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      v-model="siteNb"
                      outlined
                      :rules="[required]"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12" v-for="s of siteNb" :key="s">
                <b>{{ "Site " + s }}:</b>
                <v-row class="mt-3">
                  <v-col lg="6" cols="12">
                    <v-text-field
                      :rules="[required]"
                      filled
                      :name="`name[${s}]`"
                      :label="$t('instName')"
                    ></v-text-field>
                  </v-col>

                  <v-col lg="6" cols="12">
                    <v-text-field
                      :rules="[required]"
                      filled
                      :name="`contact[${s}]`"
                      :label="$t('formCliHosp.q9')"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-combobox
                  persistent-hint
                  :hint="$t('formCliHosp.q10o1')"
                  :label="$t('formCliHosp.q10')"
                  :items="
                    specialities.map((e) => ({
                      text: $i18n.locale == 'en' ? e.name_en : e.name,
                      value: e.id,
                    }))
                  "
                  :name="`specialities[${s}]`"
                  v-model="selectedSpecialities[`spe${s}`]"
                  :rules="[required2]"
                  clearable
                  multiple
                  outlined
                  chips
                ></v-combobox>

                <v-divider></v-divider>
              </v-col>

              <v-col lg="6" md="6" cols="12">
                <v-text-field
                  :rules="[required]"
                  filled
                  name="number_of_beds"
                  :prefix="$t('formCliHosp.q11o1')"
                  type="number"
                  hide-spin-buttons
                  :suffix="$t('formCliHosp.q11o2')"
                ></v-text-field
              ></v-col>

              <v-col lg="6" md="6" cols="12">
                <v-text-field
                  :rules="[required]"
                  filled
                  name="number_of_collabs"
                  :prefix="$t('formCliHosp.q12o1')"
                  type="number"
                  hide-spin-buttons
                  :suffix="$t('formCliHosp.q12o2')"
                ></v-text-field>
              </v-col>

              <v-col lg="6" md="6" cols="12">
                <b class="mb-5">{{ $t("common.q8") }}:</b>
                <v-text-field
                  :rules="[required]"
                  filled
                  name="foreign_patient_rate"
                  :prefix="$t('common.q8o1')"
                  type="number"
                  hide-spin-buttons
                  :suffix="$t('common.q8o2')"
                  value="0"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
                <b>{{ $t("common.q9") }}:</b>
                <v-checkbox
                  name="gap_analysis"
                  :label="$t('common.q9o1')"
                  value="1"
                ></v-checkbox>
                <v-checkbox
                  name="simulation_of_accreditation"
                  :label="$t('common.q9o2')"
                  value="1"
                ></v-checkbox>
                <v-checkbox
                  name="team_support"
                  :label="$t('common.q9o3')"
                  value="1"
                ></v-checkbox>
                <v-checkbox
                  name="management_trainings"
                  :label="$t('common.q9o4')"
                  value="1"
                ></v-checkbox>
                <v-checkbox
                  name="technical_trainings"
                  :label="$t('common.q9o5')"
                  value="1"
                ></v-checkbox>
                <v-checkbox
                  name="foreign_patients_trainings"
                  :label="$t('common.q9o6')"
                  value="1"
                ></v-checkbox>
              </v-col>

              <v-col cols="12"
                ><v-divider></v-divider>
                <v-textarea
                  :label="$t('otherInfo')"
                  :hint="$t('otherInfoHint')"
                  persistent-hint
                  v-model="othersComments"
                  outlined
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>

                <v-btn class="mt-3" type="submit" color="primary">{{
                  $t("submit")
                }}</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title v-if="!err" class="text-h5 success lighten-3">
            {{ $t("confirmed") }}
          </v-card-title>
          <v-card-title v-else class="text-h5 red lighten-3">
            Oops
          </v-card-title>

          <v-card-text v-if="!err" style="padding: 20px">
            {{ $t("confirmedText") }}
          </v-card-text>

          <v-card-text v-else style="padding: 20px">
            {{ $t("errorText") }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!err" color="primary" text> OK </v-btn>
            <v-btn v-else color="primary" text @click="dialog = false">
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import { get, post, put } from "@/plugins/axios";
import $ from "jquery";
export default {
  beforeMount() {
    this.getSpec();
  },
  mounted() {
    this.isValid();
  },
  methods: {
    async isValid() {
      // let token = this.$route.query.token;
      const { res } = await get(`/api/validate/${this.$route.query.token}`)
        .then((res) => {
          console.log("all is good : ", res);
        })
        .catch((err) => {
          this.$router.push("/expired");
          console.log(err);
        });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.err = false;
        this.loading = true;
        let payload = {};
        $("form")
          .serializeArray()
          .forEach((e) => (payload[e.name] = e.value));
        payload.number_of_sites = this.siteNb;
        payload.sites = [];
        payload.token = this.$route.query.token;
        payload.others_comments = this.othersComments;
        for (let s = 1; s <= this.siteNb; s++) {
          let obj = {};
          obj["contact"] = payload[`contact[${s}]`];
          obj["name"] = payload[`name[${s}]`];
          obj["specialities"] = [];
          obj["others_specialities"] = [];
          this.selectedSpecialities[`spe${s}`].forEach((e) => {
            e.value
              ? obj["specialities"].push(e.value)
              : obj["others_specialities"].push(e);
          });
          payload.sites.push(obj);
          delete payload[`contact[${s}]`];
          delete payload[`name[${s}]`];
          delete payload[`specialities[${s}]`];
        }
        try {
          await post(`/api/register/${this.$route.query.inst}/update`, payload);

          this.loading = false;
          this.$router.push("/validated");
        } catch (e) {
          this.loading = false;
          this.dialog = true;
          this.err = true;
        }
      }
    },
    async getSpec() {
      this.loading = true;
      try {
        const { data } = await get("/api/speciality");
        this.specialities = data;
        this.loading = false;
      } catch (e) {}
    },
  },
  watch: {
    isMultiS() {
      if (this.isMultiS == 0) {
        this.siteNb = 1;
      }
    },
  },
  data() {
    return {
      loading: false,
      siteNb: 1,
      specialities: [],
      selectedSpecialities: {},
      othersComments: "",
      isMultiS: "",
      isGroup: false,
      dialog: false,
      err: false,
      percentage: (v) =>
        (Number(v) >= 0 && Number(v) <= 100) || "Pourcentage invalide",
      required: (v) => !!v || this.$t("required"),
      required2: (v) => v.length || this.$t("required"),
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalide",
    };
  },
};
</script>

<style>
.v-input--hide-spin-buttons input[type="number"] {
  text-align: center !important;
}
b {
  font-size: 20px;
}
</style>
