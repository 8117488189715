<template>
  <v-row justify="center">
    <v-col lg="9" class="text-center">
      <v-icon class="mb-5" color="success" size="80"
        >fas fa-check-circle</v-icon
      >
      <div v-if="prevRoute?.name == 'home'">
        <h1>
          {{ $t("confirmedPage.1") }}
        </h1>
        <p>
          {{ $t("confirmedPage.2") }}
        </p>
      </div>
      <div v-else>
        <h1>
          {{ $t("confirmedPage.3") }}
        </h1>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({ prevRoute: undefined }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style></style>
