<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
.navbar {
  @media (min-width: 1400px) {
    padding-left: 400px;
    padding-right: 400px;
  }
}
</style>
