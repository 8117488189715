<template>
  <v-card elevation="2" outlined>
    <v-card-title>Etablissements</v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="list"
      :items-per-page="15"
    >
      <template v-slot:item.show="{ item }">
        <v-btn
          small
          outlined
          color="primary"
          :to="`/admin/institution/${item.id}`"
        >
          Voir
        </v-btn>
      </template>
    </v-data-table></v-card
  >
</template>

<script>
import { get } from "@/plugins/axios";
import moment from "moment";

export default {
  name: "institution",
  data: () => ({
    list: [],
    loading: false,
    headers: [
      {
        text: "Nom",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Nombre de sites",
        align: "start",
        sortable: true,
        value: "number_of_sites",
      },
      {
        text: "Pays",
        align: "start",
        sortable: true,
        value: "country",
      },
      {
        text: "adresse",
        align: "start",
        sortable: true,
        value: "address",
      },
      {
        text: "Site web",
        align: "start",
        sortable: false,
        value: "website",
      },
      {
        text: "Inscrit le",
        align: "start",
        sortable: true,
        value: "created_at",
      },
      {
        text: "Voir détails",
        align: "end",
        sortable: true,
        value: "show",
      },
    ],
  }),
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        const { data } = await get("/api/institution", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.list = data.data.map((e) => {
          e.created_at = moment(e.created_at).format("DD/MM/YYYY  HH:mm");
          console.log(e);
          return e;
        });
        this.loading = false;
      } catch (e) {
        if (e.response?.status == 401) this.$router.push("/admin/login");
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
