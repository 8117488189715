import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"9"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 red lighten-3"},[_c(VIcon,[_vm._v("fas fa-exclamation-circle")]),_c('h3',{staticClass:"mx-3"},[_vm._v("Ce lien est invalide ou expiré!")])],1),_c(VCardText,{staticClass:"text-center py-5"},[_c('b',[_vm._v("Veuiller cliquer sur le bouton en dessous et remplir le formulaire, vous aller recevoir un email contenant le nouveau lien:")]),_c('br'),_c(VBtn,{staticClass:"mt-5",attrs:{"color":"primary","to":"/"}},[_vm._v("vers le formulaire "),_c(VIcon,{staticClass:"ml-3"},[_vm._v("fas fa-arrow-right")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }