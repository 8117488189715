<template>
  <v-app>
    <v-main style="background-color: #d4d8e8">
      <v-container style="max-width: 800px; padding-top: 50px">
        <v-card :loading="loading" elevation="2" outlined>
          <div class="text-center py-4">
            <img width="250" src="../../assets/euromedi_logo.png" />
          </div>
          <v-divider></v-divider>
          <v-card-text style="padding: 50px">
            <v-form @submit.prevent="login" ref="form">
              <h2 class="mb-3">Connexion administrateurs</h2>
              <v-alert
                v-if="err"
                border="right"
                type="error"
                elevation="2"
                icon="fas fa-exclamation-circle"
                >{{ err }}</v-alert
              >
              <v-text-field
                :rules="[required]"
                filled
                name="email"
                type="email"
                :label="$t('E-mail')"
              ></v-text-field>
              <v-text-field
                :rules="[required]"
                filled
                name="password"
                type="password"
                :label="$t('Mot de passe')"
              ></v-text-field>

              <v-btn type="submit" color="primary" id="login_button">
                Se connecter
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { post } from "@/plugins/axios";

export default {
  name: "login",
  data() {
    return {
      show: false,
      err: "",
      loading: false,
      required: (v) => !!v || this.$t("required"),
    };
  },
  methods: {
    async login(e) {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.err = "";
      const formData = new FormData(e.target);
      try {
        const { data } = await post("/api/login", formData);
        localStorage.setItem("token", data.token);
        //   this.$store.dispatch("Auth/setUser", data.user);
        this.$router.push("/admin/institution");
      } catch (e) {
        this.loading = false;
        if (e.response?.data?.message) {
          this.err = e.response.data.message;
        } else {
          this.err =
            "Une erreur est survenu, veuiller réesseyer ultérieurement";
        }
        console.log(e);
      }
    },
  },
};
</script>

<style>
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.input > label {
  text-align: start;
}
.input > input {
  margin-top: 6px;
  height: 38px !important;
}
/* From uiverse.io */
.btn-pers {
  position: relative;
  left: 50%;
  padding: 1em 2.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}
.btn-pers:hover {
  background-color: #198754;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translate(-50%, -7px);
}
.btn-pers:active {
  transform: translate(-50%, -1px);
}
/*  */
.alternative-option {
  text-align: center;
}
.alternative-option > span {
  color: #0d6efd;
  cursor: pointer;
}
#sign_out {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
</style>
