<template>
  <div>
    <v-card v-if="institution" elevation="2" outlined>
      <v-card-title
        ><h2>{{ institution.name }}</h2></v-card-title
      >
      <v-divider></v-divider>

      <v-card-title>Informations sur le contact</v-card-title>
      <v-row class="py-5 px-5">
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-card outlined>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>Nom:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b v-if="user">{{ user.firstname }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Prénom:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b v-if="user">{{ user.lastname }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Email:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b v-if="user">{{ user.email }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Fonction:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b v-if="user">{{ user.position }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content
                  >Date de prise de contact:</v-list-item-content
                >
                <v-list-item-content class="align-end">
                  <b v-if="user">{{ user.created_at }}</b>
                  <!--   -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-card-title>Informations générales</v-card-title>
      <v-row class="py-5 px-5">
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-card outlined>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>Nom:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b>{{ institution.name }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Catégorie:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b>{{ institution.category?.name }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>pays:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b>{{ institution.country }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Téléphone:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b>{{ institution.phone }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Site web:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <a target="_blank" :href="institution.website">{{
                    institution.website
                  }}</a>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-card outlined>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>Nombre de lits:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b>{{ institution.number_of_beds }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content
                  >Nombre de collaborateurs:</v-list-item-content
                >
                <v-list-item-content class="align-end">
                  <b>{{ institution.number_of_collabs }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Nombre de sites:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b>{{ institution.number_of_sites }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Nombre de MR-MRS:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b>{{ institution.number_of_nursing_house }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content
                  >Conseil d’administration:</v-list-item-content
                >
                <v-list-item-content class="align-end">
                  <b>{{ institution.board_of_directors ? "Oui" : "Non" }}</b>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-title>Sites</v-card-title>
      <v-row class="px-5 py-5">
        <v-col
          v-for="s of institution.sites"
          :key="s.id"
          cols="12"
          md="6"
          lg="4"
          xl="3"
        >
          <v-card color="#e4e4e4" outlined class="mx-auto">
            <v-card-text>
              <p class="text-h4">{{ s.name }}</p>
            </v-card-text>
            <v-list color="#e4e4e4" dense>
              <v-list-item>
                <v-list-item-content>Nombre de lits:</v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content class="align-end">
                  <b>{{ s.number_of_beds }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Nombre d'employés:</v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content class="align-end">
                  <b>{{ s.number_of_employees }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Malades hospitalisés:</v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content class="align-end">
                  <b>{{ s.number_of_hospitalization }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Nombre de résidents:</v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content class="align-end">
                  <b>{{ s.number_of_residents }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>TVA:</v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content class="align-end">
                  <b>{{ s.tva }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Zip:</v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content class="align-end">
                  <b>{{ s.zip }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Adresse:</v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content class="align-end">
                  <b>{{ s.address }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Contact:</v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content class="align-end">
                  <b>{{ s.contact }}</b>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Spécialité/Services:</v-list-item-content>
              </v-list-item>
              <v-slide-group class="my-3" multiple show-arrows>
                <v-slide-item
                  v-for="spec of s.specialities
                    .concat(s.services)
                    .concat(s.others_specialities.split(';'))"
                  :key="spec.id"
                >
                  <v-chip v-if="spec" outlined color="primary" class="mx-1">
                    {{ spec.name ? spec.name : spec }}
                  </v-chip>
                </v-slide-item>
              </v-slide-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <div class="text-center my-5 py-5" v-else>
      <v-row class="my-5">
        <v-spacer></v-spacer>
        <v-progress-circular
          :size="50"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-spacer></v-spacer>
      </v-row>
      <h2 class="my-5">Chargement des données...</h2>
    </div>
  </div>
</template>

<script>
import { get } from "@/plugins/axios";
import moment from "moment";

export default {
  data: () => ({
    institution: undefined,
    user: undefined,
  }),
  mounted() {
    this.getInst();
  },
  methods: {
    async getInst() {
      try {
        this.loading = true;
        const { data } = await get(
          `/api/institution/${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.institution = data.data;
        console.log("institution:", this.institution);
        if (data.data.users != null) {
          this.user = data.data.users[0];
          this.user.created_at = moment(this.user.created_at).format(
            "DD/MM/YYYY  HH:mm"
          );
          console.log("user:", this.user);
        }
      } catch (e) {
        if (e.response?.status == 401) this.$router.push("/admin/login");
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
